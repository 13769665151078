import React, { useState, useRef, useEffect } from "react";
import servicesIcon from "../../assets/img/menu-icon-4.webp";
import addNewIcon from "../../assets/img/add-new-btn-icon.webp";
import Service from "../module-tabs-detail/Service";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  addServiceAction,
  fetchServiceAction,
} from "../../Redux/Action/serviceAction";
import { fetchItemAction } from "../../Redux/Action/itemAction";
import axios from "axios";
import Loader from "../common/Loader";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import UploadButton from "../UploadVideo";

function AllServicesTab() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const allItem = useSelector((state) => state.itemReducer.allItem);
  const allService = useSelector((state) => state.serviceReducer.allService);

  const allItems = [
    { name: "Shirt", icon: require("../../assets/img/service-item-1.webp") },
    { name: "Pant", icon: require("../../assets/img/service-item-2.webp") },
    { name: "Coat", icon: require("../../assets/img/service-item-8.webp") },
    { name: "Trouser", icon: require("../../assets/img/service-item-3.webp") },
    { name: "Zipper", icon: require("../../assets/img/service-item-7.webp") },
  ];

  const [addServiceName, setAddServiceName] = useState("");
  const [services, setServices] = useState();
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [addStandardCost, setAddStandardCost] = useState("");
  const [addPremiumCost, setAddPremiumCost] = useState("");
  const [addPremiumValue, setAddPremiumValue] = useState("");
  const [ServiceType, setServiceType] = useState("");
  const [addStandardValue, setAddStandardValue] = useState("");
  const [addPinItem, setAddPinItem] = useState("");
  const [addMatchItem, setAddMatchItem] = useState("");
  const [addMeasureItem, setAddMeasureItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const wrapperRef = useRef(null);

  const deleteItem = (serviceName, itemName) => {
    setServices((prevServices) => {
      return prevServices.map((service) => {
        if (service.serviceName === serviceName) {
          return {
            ...service,
            serviceItemList: service.serviceItemList.filter(
              (item) =>
                item.serviceItemName !== itemName || item.name !== itemName
            ),
          };
        }
        return service;
      });
    });
  };

  const navigate = useNavigate();
  const openAddPopup = () => {
    setShowAddPopup(true);
  };
  const closeAddPopup = () => {
    setShowAddPopup(false);
  };
  const handleAddServiceName = (e) => {
    setAddServiceName(e.target.value);
  };
  const handleDeleteItem = (name) => {
    setSelectedItems(selectedItems.filter((item) => item.name !== name));
    navigate("/services");
  };

  const today = new Date();
  const formattedDate = format(today, "do MMMM, EEEE");

  const handleAddIsPremium = (value) => {
    setAddPremiumValue(value);
  };
  const handleServiceType = (value) => {
    setServiceType(value);
  };
  const handleAddIsStandard = (value) => {
    setAddStandardValue(value);
  };
  const handleAddStandardCost = (e) => {
    setAddStandardCost(e.target.value);
  };
  const handleAddPremiumCost = (e) => {
    setAddPremiumCost(e.target.value);
  };
  const handleAddPinItem = (value) => {
    setAddPinItem(addPinItem === "Pin Items" ? "" : "Pin Items");
  };
  const handleAddMatchItem = (value) => {
    setAddMatchItem(addMatchItem === "Match Items" ? "" : "Match Items");
  };
  const handleAddMeasureItem = (value) => {
    setAddMeasureItem(
      addMeasureItem === "Measure Items" ? "" : "Measure Items"
    );
  };
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setFilteredOptions(
      allItems.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    setIsOpen(true);
  };

  const handleOptionClick = (name, icon) => {
    if (!selectedItems?.some((item) => item.name === name)) {
      setSelectedItems([...selectedItems, { name, icon }]);
    }
    setSearchTerm("");
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const [pinItemVideoData, setpinItemVideoData] = useState(null);

  const CreateServieHandler = () => {
    if (!addServiceName.trim()) {
      toast.error("Service Name is required");
      return;
    }

    if (selectedItems.length === 0) {
      toast.error("Items are required");
      return;
    }
    if (!addPremiumValue.trim()) {
      toast.error("Premium Value is required");
      return;
    }
    if (!addPremiumCost.trim()) {
      toast.error("Premium Cost is required");
      return;
    }
    if (!addStandardValue.trim()) {
      toast.error("Standard Value is required");
      return;
    }
    if (!ServiceType.trim()) {
      toast.error("Service Type is required");
      return;
    }
    if (addPinItem && !pinItemVideoData) {
      toast.error("Pin Item Video is required when Pin Item is selected");
      return;
    }

    const infoMode = [];
    if (addPinItem) infoMode.push(addPinItem);
    if (addMatchItem) infoMode.push(addMatchItem);
    if (addMeasureItem) infoMode.push(addMeasureItem);

    dispatch(
      addServiceAction({
        name: addServiceName,
        items: selectedItems,
        is_premium: addPremiumValue === "Yes" ? true : false,
        premium_price: addPremiumCost,
        is_standard: addStandardValue === "Yes" ? true : false,
        standard_price: addStandardCost,
        info_mode: infoMode,
        service_type: ServiceType === "Yes" ? false : true,
        pin_item_video: pinItemVideoData,
      })
    );

    setAddServiceName("");
    setSelectedItems([]);
    setAddPremiumValue("");
    setAddPremiumCost("");
    setAddStandardCost("");
    setAddStandardValue("");
    setServiceType("");
    closeAddPopup();
    navigate("/services");
  };


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchServiceAction());
    dispatch(fetchItemAction());
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [allService]);
  return (
    <div className="tab-detail-outer-box services-listing-box">
      <div className="head-box flex-box space-between align-center">
        <h6 className="tab-detail-heading">
          {" "}
          <span>
            <img src={servicesIcon} alt="" />
          </span>{" "}
          Services Listing
        </h6>
        <h6 className="add-new-btn" onClick={openAddPopup}>
          {" "}
          <span>
            <img src={addNewIcon} alt="" />
          </span>{" "}
          Add New Service
        </h6>
        {showAddPopup && (
          <div className="popup-outer-box">
            <div className="popup-box user-edit-popup service-popup service-edit-popup add-service-popup">
              <button className="popup-close-btn" onClick={closeAddPopup}>
                <img src={popupCloseIcon} alt="" />
              </button>
              <div className="popup-name-box">
                <div className="field-box">
                  <h6>Service Name</h6>
                  <input
                    className="popup-input full-width"
                    value={addServiceName}
                    onChange={handleAddServiceName}
                    type="text"
                    placeholder="Write Service Name"
                  />
                  <h6 className="green-box">Added On : {formattedDate}</h6>
                </div>
              </div>
              <div className="popup-item-list-box relative">
                <div className="add-new-associated-box">
                  <div className="search-select" ref={wrapperRef}>
                    <input
                      type="text"
                      placeholder="Search Items"
                      value={searchTerm}
                      onChange={handleInputChange}
                      onClick={() => setIsOpen(true)}
                    />
                    {isOpen && (
                      <ul>
                        {allItem?.map((item, index) => {
                          return (
                            <li
                              key={index}
                              onClick={() =>
                                handleOptionClick(item.name, item.image)
                              }
                            >
                              {item.name}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
                <h6>Associated Items</h6>
                <div className="popup-item-list flex-box">
                  {selectedItems?.map((item, index) => (
                    <div className="popup-item" key={index}>
                      <div className="item-img-box relative">
                        <img
                          className="service-item-icon"
                          src={item.icon}
                          alt={""}
                        />
                        <img
                          className="delete-item-btn"
                          alt=""
                          src={popupCloseIcon}
                          onClick={() => handleDeleteItem(item.name)}
                        />
                      </div>
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              </div>
              <div className="popup-type-box">
                <div className="type-box flex-box align-center space-between">
                  <div className="left-box flex-box align-center">
                    <h4>Service Type</h4>
                    <div className="yes-no-checkbox">
                      <label
                        className={ServiceType === "Yes" ? "box-selected" : ""}
                      >
                        <input
                          type="checkbox"
                          checked={ServiceType === "Yes"}
                          onChange={() => handleServiceType("Yes")}
                        />
                        <span className="checkbox-custom"></span>
                        Repair
                      </label>
                      <label
                        className={ServiceType === "No" ? "box-selected" : ""}
                      >
                        <input
                          type="checkbox"
                          checked={ServiceType === "No"}
                          onChange={() => handleServiceType("No")}
                        />
                        <span className="checkbox-custom"></span>
                        Alteration
                      </label>
                    </div>
                  </div>
                  {/* <input className='popup-input' value={addPremiumCost} onChange={handleAddPremiumCost} type="text" placeholder="$ 400.00" /> */}
                </div>
                <div className="type-box flex-box align-center space-between">
                  <div className="left-box flex-box align-center">
                    <h4>Is Premium</h4>
                    <div className="yes-no-checkbox">
                      <label
                        className={
                          addPremiumValue === "Yes" ? "box-selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={addPremiumValue === "Yes"}
                          onChange={() => handleAddIsPremium("Yes")}
                        />
                        <span className="checkbox-custom"></span>
                        Yes
                      </label>
                      <label
                        className={
                          addPremiumValue === "No" ? "box-selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={addPremiumValue === "No"}
                          onChange={() => handleAddIsPremium("No")}
                        />
                        <span className="checkbox-custom"></span>
                        No
                      </label>
                    </div>
                  </div>
                  <input
                    className="popup-input"
                    value={addPremiumCost}
                    onChange={handleAddPremiumCost}
                    type="text"
                    placeholder="$ 400.00"
                  />
                </div>
                <div className="type-box flex-box align-center space-between">
                  <div className="left-box flex-box align-center">
                    <h4>Is Standard</h4>
                    <div className="yes-no-checkbox">
                      <label
                        className={
                          addStandardValue === "Yes" ? "box-selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={addStandardValue === "Yes"}
                          onChange={() => handleAddIsStandard("Yes")}
                        />
                        <span className="checkbox-custom"></span>
                        Yes
                      </label>
                      <label
                        className={
                          addStandardValue === "No" ? "box-selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={addStandardValue === "No"}
                          onChange={() => handleAddIsStandard("No")}
                        />
                        <span className="checkbox-custom"></span>
                        No
                      </label>
                    </div>
                  </div>
                  <input
                    className="popup-input"
                    value={addStandardCost}
                    onChange={handleAddStandardCost}
                    type="text"
                    placeholder="$ 400.00"
                  />
                </div>
                <div className="type-box">
                  <div className="left-box flex-box align-center">
                    <h4>Mode of info</h4>
                    <div
                      className="yes-no-checkbox mode-of-info-edit"
                      style={{ marginTop: "1rem" }}
                    >
                      <label
                        className={
                          addPinItem === "Pin Items" ? "box-selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={addPinItem === "Pin Items"}
                          onChange={() => handleAddPinItem("Pin Items")}
                        />
                        <span className="checkbox-custom"></span>
                        Pin Items
                      </label>
                      <input
                        type="text"
                        className="popup-input full-width"
                        placeholder="Pin Items Video"
                        onChange={(e) => setpinItemVideoData(e.target.value)}
                      />
                      <label
                        className={
                          addMatchItem === "Match Items" ? "box-selected" : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={addMatchItem === "Match Items"}
                          onChange={() => handleAddMatchItem("Match Items")}
                        />
                        <span className="checkbox-custom"></span>
                        Match Items
                      </label>
                      <label
                        className={
                          addMeasureItem === "Measure Items"
                            ? "box-selected"
                            : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={addMeasureItem === "Measure Items"}
                          onChange={() => handleAddMeasureItem("Measure Items")}
                        />
                        <span className="checkbox-custom"></span>
                        Measure Items
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bottom-save-box"
                style={{ marginTop: "0.8rem" }}
                onClick={() => CreateServieHandler()}
              >
                <button className="save-info-btn">Save</button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="all-orders-listing">
          {allService.map((item, index) => {
            let newData = {
              id: item._id,
              serviceName: item.name,
              category:
                item.is_premium && item.is_standard
                  ? "Premium , Standard"
                  : item.is_premium
                    ? "Premium"
                    : "Standard",
              itemsAssociated: item.items.map((el) => el.name),
              addedOn: new Date(item.createdAt).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }),
              serviceItemList: item.items.map((el) => ({
                serviceItemIcon: el.icon,
                serviceItemName: el.name,
              })),
              isPremium: item.is_premium ? "Yes" : "No",
              isStandard: item.is_standard ? "Yes" : "No",
              premiumCost: item.premium_price,
              standardCost: item.standard_price,
              modeOfInfo: {
                pinItems: item.info_mode.includes("Pin Items") && "Pin Items",
                matchItems:
                  item.info_mode.includes("Match Items") && "Match Items",
                measureItems:
                  item.info_mode.includes("Measure Items") && "Measure Items",
              },
              service_type: item.service_type ? "Alteration" : "Repair",
              pin_item_video: item.pin_item_video,
            };

            return (
              <Service
                key={index}
                service={newData}
                deleteItem={deleteItem}
                setServices={setServices}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default AllServicesTab;
