import { useState } from "react";
import discountsIcon from "../../assets/img/menu-icon-7.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../common/Loader";
import HubspotsLogs from "../module-tabs-detail/HubspotLogs";
import { useQuery } from "react-query";

const AllHubSpotsTab = () => {
    const [loading, setIsLoading] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);

    const closeAddPopup = () => {
        setShowAddPopup(false);
    };

    const openAddPopup = () => {
        setShowAddPopup(true);
    };

    const [showAddPopupContact, setShowAddPopupContact] = useState(false);

    const closeAddPopupContact = () => {
        setShowAddPopupContact(false);
    };

    const openAddPopupContact = () => {
        setShowAddPopupContact(true);
    };

    const fetchHubSpotLogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}order/getallfromhs`);
        return response.data;
    }

    const {
        data, isLoading
    } = useQuery("fetchHubSpotLogs", fetchHubSpotLogs)

    const syncHubspots = async () => {
        // setIsLoading(true)
        toast.success("Syncing of Deals is in progress, please wait...")
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}order/syncorderandcontacts`)
        // toast.success("Syncing of Hubspots is in progress")
        if (resp.status === 200) {
            // setIsLoading(false)
            toast.success("Syncing of Hubspots is successful")
        }
        setShowAddPopup(false)
    }

    const syncHubspotContacts = async () => {
        // setIsLoading(true)
        toast.success("Syncing of Contacts is in progress, please wait...")
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}order/sync/contacts`)
        // toast.success("Syncing of Hubspots is in progress")
        if (resp.status === 200) {
            // setIsLoading(false)
            toast.success("Syncing of Hubspots is successful")
        }
        setShowAddPopupContact(false)
    }

    return (
        <>
            <div className="tab-detail-outer-box discounts-listing-box">
                <div className="head-box flex-box space-between align-center">
                    <h6 className="tab-detail-heading">
                        {" "}
                        <span>
                            <img src={discountsIcon} alt="" />
                        </span>{" "}
                        Hubspots Overview
                    </h6>
                </div>
                <ul className="all-orders-listing">
                    <button type="button" onClick={openAddPopup} className="save-info-btn" style={{ width: "20%" }}>Sync To Hubspot</button>
                    {/* <button type="button" onClick={openAddPopupContact} className="save-info-btn" style={{ width: "20%" }}>Sync Contacts</button> */}
                </ul>
                {showAddPopup &&
                    <>
                        <div className="popup-outer-box">
                            <div className="popup-box user-edit-popup item-popup fixer-popup add-discount-popup">
                                <button className="popup-close-btn" onClick={closeAddPopup}>
                                    <img src={popupCloseIcon} alt="" />
                                </button>
                                <div className="popup-edit-box">
                                    <h4 className="popup-heading">Are you sure you want to sync your orders to Hubspots?</h4>
                                </div>
                                <div
                                    className="bottom-save-box flex-box space-between align-center"
                                    onClick={syncHubspots}
                                >
                                    <button className="save-info-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {showAddPopupContact &&
                    <>
                        <div className="popup-outer-box">
                            <div className="popup-box user-edit-popup item-popup fixer-popup add-discount-popup">
                                <button className="popup-close-btn" onClick={closeAddPopupContact}>
                                    <img src={popupCloseIcon} alt="" />
                                </button>
                                <div className="popup-edit-box">
                                    <h4 className="popup-heading">Are you sure you want to sync your contacts to Hubspots?</h4>
                                </div>
                                <div
                                    className="bottom-save-box flex-box space-between align-center"
                                    onClick={syncHubspotContacts}
                                >
                                    <button className="save-info-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <h6 className="tab-detail-heading">
                    All Logs
                </h6>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <ul className="all-orders-listing">
                            {data?.results?.map((discounts, index) => (
                                <HubspotsLogs key={index} log={discounts} />
                            ))}
                        </ul>
                        {/* <ul className="all-orders-listing">
                            {data?.data?.map((discounts, index) => (
                                <HubspotsLogs key={index} log={discounts} />
                            ))}
                        </ul> */}
                    </>
                )}
            </div>
        </>
    )
}

export default AllHubSpotsTab;