import React, { useEffect, useState } from "react";
import discountsIcon from "../../assets/img/menu-icon-7.webp";
import Discount from "../module-tabs-detail/Discounts";
import addNewIcon from "../../assets/img/add-new-btn-icon.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addDiscountAction,
  fetchDiscountAction,
} from "../../Redux/Action/discountAction";
import Loader from "../common/Loader";
import toast from "react-hot-toast";
import axios from "axios";

function AllDiscountsTab() {
  const dispatch = useDispatch();
  const allDiscount = useSelector((state) => state.discountReducer.allDiscount);
  const [discounts, setDiscount] = useState([]);

  const [showAddPopup, setShowAddPopup] = useState(false);
  const [newOfferName, setNewOfferName] = useState();
  const [newCouponCode, setNewCouponCode] = useState();
  const [newOfferAmount, setNewOfferAmount] = useState();
  const [typeFixed, setTypeFixed] = useState();
  const [typePercentage, setTypePercentage] = useState("");
  const [premiumRepair, setPremiumRepair] = useState("");
  const [premiumAlteration, setPremiumAlteration] = useState("");
  const [standardRepair, setStandardRepair] = useState("");
  const [standardAlteration, setStandardAlteration] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [perOrder, setPerOrder] = useState("");
  const [perCustomer, setPerCustomer] = useState("");

  const openAddPopup = () => {
    setNewOfferName();
    setNewCouponCode();
    setNewOfferAmount();

    setShowAddPopup(true);
  };
  const closeAddPopup = () => {
    setShowAddPopup(false);
  };
  const handleNewOffer = (e) => {
    setNewOfferName(e.target.value);
  };
  const handleNewCouponCode = (e) => {
    setNewCouponCode(e.target.value.toUpperCase());
  };
  const handleNewOfferAmount = (e) => {
    setNewOfferAmount(e.target.value);
  };
  const handleTypeFixed = (value) => {
    setTypePercentage();
    setTypeFixed(typeFixed === "Fixed" ? "" : "Fixed");
  };
  const handleTypePercentage = (value) => {
    setTypeFixed();
    setTypePercentage(typePercentage === "Percentage" ? "" : "Percentage");
  };
  const handlePremiumRepair = (value) => {
    setPremiumRepair(
      premiumRepair === "Premium - Repair" ? "" : "Premium - Repair"
    );
  };
  const handlePremiumAlteration = (value) => {
    setPremiumAlteration(
      premiumAlteration === "Premium - Alteration" ? "" : "Premium - Alteration"
    );
  };
  const handleStandardRepair = (value) => {
    setStandardRepair(
      standardRepair === "Standard - Repair" ? "" : "Standard - Repair"
    );
  };
  const handleStandardAlteration = (value) => {
    setStandardAlteration(
      standardAlteration === "Standard - Alteration"
        ? ""
        : "Standard - Alteration"
    );
  };
  const handlePerOrder = (e) => {
    setPerCustomer();
    setPerOrder(perOrder === "Once per order" ? "" : "Once per order");
  };
  const handlePerCustomer = (e) => {
    setPerOrder();
    setPerCustomer(
      perCustomer === "Once per customer" ? "" : "Once per customer"
    );
  };

  const createDiscountHandler = () => {
    if (
      !(newOfferName,
        newCouponCode,
        newOfferAmount,
        typeFixed,
        typePercentage,
        premiumRepair,
        premiumAlteration,
        standardRepair,
        standardAlteration,
        startDate,
        endDate)
    ) {
      toast.error("Please submit all fields!");
    }
    if (
      (newOfferName,
        newCouponCode,
        newOfferAmount,
        typeFixed,
        typePercentage,
        premiumRepair,
        premiumAlteration,
        standardRepair,
        standardAlteration,
        startDate,
        endDate)
    ) {
      const data = {
        name: newOfferName,
        coupon_code: newCouponCode,
        type: typeFixed === "Fixed" ? "fixed" : "percentage",
        amount: newOfferAmount,
        category: {
          standard_repair: standardAlteration,
          premium_repair: premiumRepair,
          standard_alteration: standardAlteration,
          premium_alteration: premiumAlteration,
        },
        start_date: startDate,
        end_date: endDate,
        usage_type: perOrder === "Once per order" ? "perorder" : "percustomer",
      };
      dispatch(addDiscountAction(data));
      setShowAddPopup(false);
    }
  };

  useEffect(() => {
    dispatch(fetchDiscountAction());
  }, []);

  useEffect(() => {
    if (allDiscount) {
      setDiscount(
        allDiscount?.map((item) => ({
          discountName: item.name,
          discountType: item.type,
          discountCategory: item.category
            .map((el) => Object.values(el))
            .join(", "),
          discountAmount: item.amount,
          discountStart: new Date(item.start_date).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }),
          discountEnd: new Date(item.end_date).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" }),
          discountUsage: item.usage_type,
          code : item.coupon_code
        }))
      );
    }
  }, [allDiscount]);

  return (
    <div className="tab-detail-outer-box discounts-listing-box">
      <div className="head-box flex-box space-between align-center">
        <h6 className="tab-detail-heading">
          {" "}
          <span>
            <img src={discountsIcon} alt="" />
          </span>{" "}
          All Discounts
        </h6>
        <h6 className="add-new-btn" onClick={openAddPopup}>
          {" "}
          <span>
            <img src={addNewIcon} alt="" />
          </span>{" "}
          Add New Discount
        </h6>
        {showAddPopup && (
          <div className="popup-outer-box">
            <div className="popup-box user-edit-popup item-popup fixer-popup add-discount-popup">
              <button className="popup-close-btn" onClick={closeAddPopup}>
                <img src={popupCloseIcon} alt="" />
              </button>
              <div className="popup-edit-box">
                <div className="user-text-box flex-box space-between">
                  <div className="field-box w-50">
                    <h6>Discount Name</h6>
                    <input
                      className="popup-input"
                      value={newOfferName}
                      onChange={handleNewOffer}
                      type="text"
                    />
                  </div>
                  <div className="field-box w-50">
                    <h6>Set Coupon Code</h6>
                    <input
                      className="popup-input"
                      value={newCouponCode}
                      onChange={handleNewCouponCode}
                      type="text"
                    />
                  </div>
                </div>
                <div className="select-type-box">
                  <div className="yes-no-checkbox">
                    <h6>Select Type</h6>
                    <label
                      className={typeFixed === "Fixed" ? "box-selected" : ""}
                    >
                      <input
                        type="checkbox"
                        checked={typeFixed === "Fixed"}
                        onChange={() => handleTypeFixed("Fixed")}
                      />
                      <span className="checkbox-custom"></span>
                      Fixed
                    </label>
                    <label
                      className={
                        typePercentage === "Percentage" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={typePercentage === "Percentage"}
                        onChange={() => handleTypePercentage("Percentage")}
                      />
                      <span className="checkbox-custom"></span>
                      Percentage
                    </label>
                  </div>
                </div>
                <div className="field-box">
                  <h6>Offer Amount</h6>
                  <input
                    className="popup-input width-full"
                    value={newOfferAmount}
                    onChange={handleNewOfferAmount}
                    type="text"
                    placeholder="$ 20.00"
                  />
                </div>
                <div className="select-category-box select-type-box">
                  <div className="yes-no-checkbox">
                    <h6>Category</h6>
                    <div className="box">
                      <label
                        className={
                          standardAlteration === "Standard - Alteration"
                            ? "box-selected"
                            : ""
                        }
                      >
                        <input
                          type="checkbox"
                          // checked={
                          //   standardAlteration === "Standard - Alteration"
                          // }
                          onChange={() =>
                            handleStandardAlteration("Standard - Alteration")
                          }
                        />
                        <span className="checkbox-custom"></span>
                        Standard - Alteration
                      </label>
                      <label
                        className={
                          standardRepair === "Standard - Repair"
                            ? "box-selected"
                            : ""
                        }
                      >
                        <input
                          type="checkbox"
                          // checked={standardRepair === "Standard - Repair"}
                          onChange={() =>
                            handleStandardRepair("Standard - Repair")
                          }
                        />
                        <span className="checkbox-custom"></span>
                        Standard - Repair
                      </label>
                    </div>
                    <div className="box">
                      <label
                        className={
                          premiumAlteration === "Premium - Alteration"
                            ? "box-selected"
                            : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={premiumAlteration === "Premium - Alteration"}
                          onChange={() =>
                            handlePremiumAlteration("Premium - Alteration")
                          }
                        />
                        <span className="checkbox-custom"></span>
                        Premium - Alteration
                      </label>
                      <label
                        className={
                          premiumRepair === "Premium - Repair"
                            ? "box-selected"
                            : ""
                        }
                      >
                        <input
                          type="checkbox"
                          checked={premiumRepair === "Premium - Repair"}
                          onChange={() =>
                            handlePremiumRepair("Premium - Repair")
                          }
                        />
                        <span className="checkbox-custom"></span>
                        Premium - Repair
                      </label>
                    </div>
                  </div>
                </div>
                <div className="date-pickup-box flex-box space-between">
                  <div className="field-box w-50 date-box-icon relative">
                    <h6>Start</h6>
                    <DatePicker
                      selected={startDate}
                      className="date-picker"
                      onChange={(date) => setStartDate(date)}
                      dateFormat="do MMMM, EEEE"
                      placeholderText="Select a date"
                    />
                  </div>
                  <div className="field-box w-50 date-box-icon relative">
                    <h6>Has End Date</h6>
                    <DatePicker
                      selected={endDate}
                      className="date-picker"
                      onChange={(date) => setEndDate(date)}
                      dateFormat="do MMMM, EEEE"
                      placeholderText="Select a date"
                    />
                  </div>
                </div>
                <div className="select-usage-box">
                  <div className="yes-no-checkbox">
                    <h6>Usage</h6>
                    <label
                      className={
                        perOrder === "Once per order" ? "box-selected" : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={perOrder === "Once per order"}
                        onChange={() => handlePerOrder("Once per order")}
                      />
                      <span className="checkbox-custom"></span>
                      Once per order
                    </label>
                    <label
                      className={
                        perCustomer === "Once per customer"
                          ? "box-selected"
                          : ""
                      }
                    >
                      <input
                        type="checkbox"
                        checked={perCustomer === "Once per customer"}
                        onChange={() => handlePerCustomer("Once per customer")}
                      />
                      <span className="checkbox-custom"></span>
                      Once per customer
                    </label>
                    <h4>
                      {" "}
                      {perCustomer} {perOrder}{" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="bottom-save-box flex-box space-between align-center"
                onClick={() => createDiscountHandler()}
              >
                <button className="save-info-btn">Save</button>
              </div>
            </div>
          </div>
        )}
      </div>
      {discounts?.length === 0 ? (
        <Loader />
      ) : (
        <ul className="all-orders-listing">
          {discounts?.map((discounts, index) => (
            <Discount key={index} discount={discounts} fetchDiscountAction={fetchDiscountAction} />
          ))}
        </ul>
      )}
    </div>
  );
}

export default AllDiscountsTab;
