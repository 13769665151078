function ItemTab({ title, value }) {
    return (
        <div className="order-detail-box">
            <h6>{title}</h6>
            <h4>{value || '-'}</h4>
        </div>
    );
}

const HubspotsLogs = ({ log }) => {
    console.log(log)    
    return (
        <>
            <li>
                <div className="order-box flex-box align-center space-between">
                    <ItemTab title={"Date"} value={log._id.year + "-" + log._id.month + "-" + log._id.day} />
                    <ItemTab title={"Total Entries"} value={log.totalEntries} />
                    <ItemTab title={"Status"} value={"Success"} />
                    {/* <ItemTab title={"Contacts"} value={log.contact_status == true ? "Success" : "Failed"} /> */}
                </div>
            </li>
        </>
    )
}

export default HubspotsLogs;