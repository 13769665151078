import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

const RefundForm = ({ order, suborder, setIsRefundModalOpen, refetch, totalAmount, previousRefundsAmount }) => {
    const [amount, setAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [option, setOption] = useState('');
    const [checkAlert, setCheckAlert] = useState(false);
    const [refundType, setRefundTypes] = useState('');
    const [scopeOfRefund, setScopeOfRefund] = useState('');

    const handleSubmitCheck = () => {
        setCheckAlert(true);
    }


    const refundTypes = [
        'Full', 'Partial', 'Store Credit'
    ]

    const scopesofRefund = [
        'Service not Rendered', 'Customer Dissatisfaction', 'Order Cancellation', 'Declined Full Repair Cost', 'Service Selection Error', 'Lost Item', 'Damaged Item', 'Other'
    ];

    const handleCancelForm = () => {
        setAmount('');
        setRemarks('');
        setOption('');
        setRefundTypes('');
        setScopeOfRefund('');
        setCheckAlert(false);
        setIsRefundModalOpen();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}order/refund/${order._id}`, {
                amount,
                remarks,
                option,
                sub_order_id: option,
                refundType,
                scopeOfRefund
            });
            if (resp.data === 'succeeded') {
                setAmount('');
                setRemarks('');
                setOption('');
                toast.success('Refund request succeeded successfully.');
                setCheckAlert(false);
                setIsRefundModalOpen();
                refetch();
            } else {
                toast.error('Refund request failed. Please try again later.');
            }
        } catch (error) {
            console.error('Error occurred during refund request:', error);
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(`Error: ${error.response.data.message}`);
            } else {
                setAmount('');
                setRemarks('');
                setOption('');
                setCheckAlert(false);
                setIsRefundModalOpen();
                toast.error('You have exhausted the amount of refunds available for this order.');
            }
        }
    };

    return (
        <React.Fragment>
            {!checkAlert &&
                <form onSubmit={handleSubmitCheck} style={{ width: "18rem" }}>
                    <h4>Order ID :- {order?.custom_order_id}</h4>
                    {/* Amount Input */}
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Amount: Max Refund ${parseFloat(totalAmount - previousRefundsAmount)}</label>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => {
                                if (e.target.value <= parseFloat(totalAmount - previousRefundsAmount)) {
                                    setAmount(e.target.value)
                                }
                            }}
                            placeholder="Enter amount"
                            max={parseFloat(totalAmount - previousRefundsAmount)}
                            style={{
                                padding: '10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                            required
                        />
                    </div>

                    {/* Remarks Input */}
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Reason/Remarks:</label>
                        <textarea
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                            placeholder="Enter reason or remarks"
                            rows="4"
                            style={{
                                padding: '10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        />
                    </div>

                    {/* Dropdown Input */}
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Select Sub Order:</label>
                        <select
                            value={option}
                            onChange={(e) => setOption(e.target.value)}
                            style={{
                                padding: '10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                            required
                        >
                            <option value="" disabled>
                                Select Sub Order
                            </option>
                            {suborder?.map((item, index) => (
                                <option key={index} value={item?._id}>{item?.order_id_custom}</option>
                            ))}
                        </select>
                    </div>

                    {/* Refund Type Dropdown Input */}
                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Refund Type:</label>
                        <select
                            value={refundType}
                            onChange={(e) => setRefundTypes(e.target.value)}
                            style={{
                                padding: '10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                            required
                        >
                            <option value="" disabled>
                                Select Refund Type
                            </option>
                            {refundTypes?.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>

                    {/* Scope of Refund Dropdown Input */}

                    <div style={{ marginBottom: '15px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>Scope of Refund:</label>
                        <select
                            value={scopeOfRefund}
                            onChange={(e) => setScopeOfRefund(e.target.value)}
                            style={{
                                padding: '10px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                            required
                        >
                            <option value="" disabled>
                                Select Scope of Refund
                            </option>
                            {scopesofRefund?.map((item, index) => (
                                <option key={index} value={item}>{item}</option>
                            ))}
                        </select>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        style={{
                            padding: '10px 20px',
                            borderRadius: '4px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Submit
                    </button>
                    <button
                        type="button"
                        onClick={handleCancelForm}
                        style={{
                            padding: '10px 20px',
                            borderRadius: '4px',
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Cancel
                    </button>
                </form>
            }
            {checkAlert &&
                <React.Fragment>
                    <div className="confirm-delete-box space-between align-center">
                        <h4>
                            Are you sure? This action cannot be undone.
                        </h4>
                        <div className="yes-no-tab flex-box align-center" style={{ marginTop: '10px' }}>
                            <button
                                onClick={handleSubmit}
                                type='button'
                            >
                                Yes
                            </button>
                            <button onClick={() => setCheckAlert(false)}>No</button>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default RefundForm;
